import React from 'react';
import { Row } from 'react-bootstrap';
import { Section } from '../section';
import { DescriptionItem } from '../description-item';
import { OutstaffingInfo } from '../../common';
import { useTranslation } from 'react-i18next';

import './styles.scss';

// Экран "Аутстаффинг"
export const Outstaffing: React.FC = () => {

    const { t } = useTranslation();

return(
    <Section
        id="sec-outstaffing"
        title={t('outstaffing.title')}
        color="color-2"
        wrapper
    >
        <Row className="middle">
            <div className="text-center">
                <img src="/images/features/team.png"  alt="pic"/>
            </div>
        </Row>
        <Row className="mb-3">
            {OutstaffingInfo.map((item, key) => (
                <DescriptionItem
                    path={item.path}
                    md={4}
                    sm={12}
                    xs={12}
                    title={t(item.title)}
                    imageClass="text-center"
                    titleClass="text-center"
                    key={key}
                >
                    {item.lines &&
                        <ul className="outstaffing">
                            {item.lines!.map((line, key) => (
                                <li key={key}>{t(line)}</li>
                            ))}
                        </ul>
                    }
                </DescriptionItem>
            ))}
        </Row>
        <Row className="middle features">
            <ul className="outstaffing">
                <li>{t('outstaffing.li1')}</li>
                <li>{t('outstaffing.li2')}</li>
                <li>{t('outstaffing.li3')}</li>
            </ul>
        </Row>
    </Section>
)};

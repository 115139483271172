import React, { useEffect } from 'react';
import { useController } from 'react-scroll-parallax';

// Кэширование для параллакс-эффекта
export const ParallaxCache: React.FC = () => {
    const { parallaxController } = useController();

    // Нужно, чтобы параллакс-эффект работал всегда, а не только когда страница изначально отображена сверху
    useEffect(() => {
        const handler = () => parallaxController.update();
        window.addEventListener('load', handler);
        return () => window.removeEventListener('load', handler);
    }, [parallaxController]);

    return null;
};

import React from 'react';
import { Section } from '../section';
import { YMap } from './components/ymap';

// Экран с картой
export const Map: React.FC = () => (
    <Section
        id="sec-map"
        color="color-2"
        fluid
    >
        <YMap />
    </Section>
);

import React from 'react';
import { Row } from 'react-bootstrap';
import { Section } from '../section';
import { DescriptionItem } from '../description-item';
import { DevelopingInfo } from '../../common';
import { useTranslation } from 'react-i18next';


// Экран "Разработка заказного программного обеспечения"
export const Developing: React.FC = () => {
    const { t } = useTranslation();


    return(    
    <Section
        id="sec-developing"
        //Разработка заказного программного обеспечения
        title={t("developing-info.title")}
        color="color-1"
        wrapper
    >
        <Row className="features">
            {DevelopingInfo.map((item, key) => (
                <DescriptionItem
                    path={item.path}
                    md={4}
                    sm={6}
                    xs={12}
                    title={t(item.title)}
                    imageClass="text-center"
                    key={key}
                >
                    <ul>
                        {item.lines!.map((line, key) => (
                            <li key={key}>{t(line)}</li>
                        ))}
                    </ul>
                </DescriptionItem>
            ))}
        </Row>
    </Section>
                        
     ) };

import React from 'react';
import { Col } from 'react-bootstrap';
import { ImageItem } from '../image-item';

type Props = Readonly<{
    path?: string;                          // Путь до изображения
    title?: string;                         // Заголовок
    md?: number;                            // Свойство md bootstrap
    sm?: number;                            // Свойство sm bootstrap
    xs?: number;                            // Свойство xs bootstrap
    chidren?: JSX.Element[] | JSX.Element;  // Содержимое (строки/lines)
    imageClass?: string;
    titleClass?: string;
}>;

// Общий элемент описания для большинства экранов
export const DescriptionItem: React.FC<Props> = ({ path, title, md, sm, xs, children, imageClass, titleClass }) => (
    <Col md={md} sm={sm} xs={xs}>
        {path && imageClass && (
            <ImageItem path={path} className={imageClass} />
        )}

        {title && (
            <h3 className={titleClass}>{title}</h3>
        )}
        {children}
    </Col>
);

import React from 'react';
import { ParallaxBanner } from 'react-scroll-parallax';
import {
    MainVideo,
    Projects,
    Clients,
    Developing,
    Services,
    Protection,
    Support,
    Outstaffing,
    Benefits,
    Reviews,
    News,
    Feedback,
    Map,
    ParallaxCache,
    Footer
} from '../../components';

import { Navbar } from '../../components/navbar';

import './styles.scss';

export const Home: React.FC = () => (
    <>
        <ParallaxCache />
        <Navbar />
        <MainVideo />
        <Projects />
        <Clients />

        {/* Параллакс-изображение */}
        <ParallaxBanner layers={[{
            image: '/images/pics/stuff.jpg',
            amount: 0.5
        }]} />

        <Developing />
        <Services />
        <Protection />

        {/* Параллакс-изображение */}
        <ParallaxBanner layers={[
            {
                image: '/images/pics/wall.jpg',
                amount: 0.5
            }
        ]} />

        <Support />

        {/* Параллакс-изображение */}
        <ParallaxBanner layers={[
            {
                image: '/images/pics/workspace.jpg',
                amount: 0.5
            }
        ]} />

        <Outstaffing />
        <Benefits />
        <Reviews />
        <News /> {/* TODO */}
        <Feedback /> {/* TODO */}
        <Map />
        <Footer />
    </>
);

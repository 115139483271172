import React from 'react';
import { Container } from 'react-bootstrap';

import './styles.scss';

type Props = Readonly<{
    id: string;
    color?: string;
    title?: string;
    fluid?: boolean;
    wrapper?: boolean;
    children: JSX.Element[] | JSX.Element;
}>;

export const Section: React.FC<Props> = ({ id, color, title, fluid, wrapper, children }) => (
    <section id={id} className={color}>
        <Container fluid={fluid} className={wrapper ? 'wrapper' : ''}>
            {title ? (
                <>
                    <h1 className="text-center mb-3">{title}</h1>
                    {children}
                </>
            ) : <>{children}</>}
        </Container>
    </section>
);

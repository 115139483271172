import React from 'react';
import { Carousel } from 'react-bootstrap';
import { Section } from '../section';
import { ProjectCarouselItem } from './components/projects-carousel-item';
import { ProjectsInfo } from '../../common';
import { useTranslation } from 'react-i18next';


// Экран "Несколько наших последних проектов"
export const Projects: React.FC = () => {
    const { t } = useTranslation();


 return(
    <Section
    id="sec-projects"
    title={t('last-projects.title')}
    color="color-1"
    wrapper
>
    <Carousel>
        {ProjectsInfo.map((item, key) => (
            <ProjectCarouselItem
                path={item.path}
                title={t(item.title)}
                description={t(item.description)}
                info={t(item.info)}
                key={key}
            />
        ))}
    </Carousel>
</Section>
 )
};

import React from 'react';
import { Section } from '../section';
import { FacebookPosts } from './components/facebook-posts';

import { useTranslation } from 'react-i18next';


import './styles.scss';

// Экран "Последние новости"
export const News: React.FC = () => {
    const {t} = useTranslation()
return(
    <Section
        id="sec-news"
        title={t('last-news.title')}
        color="color-1"
        wrapper
    >
        <FacebookPosts />
    </Section>
)};

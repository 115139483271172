import React from 'react';
import { Row } from 'react-bootstrap';
import { Section } from '../section';
import { DescriptionItem } from '../description-item';
import { ClientsLogo } from '../../common';
import { useTranslation } from 'react-i18next';


// Экран "Наши клиенты"
export const Clients: React.FC = () => {
   
    const { t } = useTranslation();
    return( <Section
        id="sec-clients"
        title={t('clients.title')}
        color="color-2"
        wrapper
    >
        <Row>
            {ClientsLogo.map((item, key) => (
                <DescriptionItem
                    path={item}
                    md={3}
                    sm={4}
                    xs={6}
                    imageClass="text-center"
                    key={key}
                />
            ))}
        </Row>
    </Section>)

   
            };

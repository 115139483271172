import React from 'react';
import { Row } from 'react-bootstrap';
import { Section } from '../section';
import { DescriptionItem } from '../description-item';
import { ServicesInfo } from '../../common';

import { useTranslation } from 'react-i18next';


// Экран "Корпоративные порталы"
export const Services: React.FC = () => {
    const { t } = useTranslation();


    return(
    <Section
        id="sec-services"
        title={t('services.title')}
        color="color-2"
        wrapper
    >
        <Row className="features">
            {ServicesInfo.map((item, key) => (
                <DescriptionItem
                    path={item.path}
                    md={6}
                    sm={6}
                    xs={12}
                    title={t(item.title)}
                    imageClass="text-center"
                    key={key}
                >
                    <ul>
                        {item.lines!.map((line, key) => (
                            <li key={key}>{t(line)}</li>
                        ))}
                    </ul>
                </DescriptionItem>
            ))}
        </Row>
    </Section>
)};

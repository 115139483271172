import React from 'react';

type Props = Readonly<{
    path: string;
    className: string
}>;

export const ImageItem: React.FC<Props> = ({ path, className }) => (
    <div className={className}>
        <img src={path} />
    </div>
);

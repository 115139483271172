import React from 'react';

import './styles.scss';

export const Footer: React.FC = () => (
    <footer className="color-2">
        <div>2008 - {new Date().getFullYear()} Шаресофт</div>
        <div>фото <a href="http://picls.ru">Picls</a></div>
    </footer>
);

import React from 'react';
import { Link } from 'react-router-dom';
import { Alert } from 'react-bootstrap';

export const Error: React.FC = () => (
    <div className="text-center">
        <Alert variant="danger">
            <Alert.Heading>Что-то пошло не так :(</Alert.Heading>
            <p>
                Попробуйте <Link to="/">еще раз</Link> или <a href="mailto:support@sharesoft.ru">напишите нам</a>.
            </p>
        </Alert>
    </div>
);

import React from 'react';
import { Carousel } from 'react-bootstrap';
import { ImageItem } from '../../../image-item';

import './styles.scss';

type Props = Readonly<{
    path: string;        // Путь до изображения
    title: string;       // Заголовок
    description: string; // Описание
    info: string;        // Служебное (информация о проекте)
    className?: string;  // Чтобы классы передались от карусели до слайдов, не используется напрямую
}>;

// Компонент элемента карусели для проектов
export const ProjectCarouselItem: React.FC<Props> = ({ path, title, description, info, className }) => (
    <Carousel.Item className={className}>
        <div className="project-slide">
            <ImageItem path={path} className="project-img" />
            <div className="mt-3">
                <h3 className="mb-1">{title}</h3>
                <p>{description}</p>
                <p className="text-info">{info}</p>
            </div>
        </div>
    </Carousel.Item>
);

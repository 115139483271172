import React from 'react';
import { Row } from 'react-bootstrap';
import { Section } from '../section';
import { DescriptionItem } from '../description-item';
import { SupportInfo } from '../../common';
import { useTranslation } from 'react-i18next';

// Экран "Поддержка и сопровождение"
export const Support: React.FC = () => {
    const { t } = useTranslation();


    return(
    <Section
        id="sec-support"
        title={t('support.title')}
        color="color-1"
        wrapper
    >
        <Row className="features">
            {SupportInfo.map((item, key) => (
                <DescriptionItem
                    path={item.path}
                    md={6}
                    sm={6}
                    xs={12}
                    title={t(item.title)}
                    imageClass="text-center"
                    key={key}
                >
                    <ul>
                        {item.lines!.map((line, key) => (
                            <li key={key}>{t(line)}</li>
                        ))}
                    </ul>
                </DescriptionItem>
            ))}
        </Row>
    </Section>
)};

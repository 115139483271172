export const appId = '1329011377289870';
export const pageId = '1476834779292911';
export const access_token = 'EAAS4upMLuo4BANeSyT0U5KQ7627tdSZAdsAHT5B505CtTI4BmatQz34s0aG0YibQrjY12RfnZBlfGxoLa50r2mzJLGkbmZCS41Do9AxpSZCglzrXHHjxMhZC2AaSEUscf9b2w8Yi3PZAZAM0n7yOrozOBEiI6AARSZCYFQpbcNnaCwsaumxupe83';

export const ClientsLogo: Readonly<string[]> = [
    '/images/clients/sberbank.png',
    '/images/clients/microsoft.png',
    '/images/clients/vtb.png',
    '/images/clients/mgts.png',
    '/images/clients/vsk.png',
    '/images/clients/sochi2014.png',
    '/images/clients/toyota.png',
    '/images/clients/lexus.png',
    '/images/clients/severstal.png',
    '/images/clients/gtnn.png',
    '/images/clients/pf-mgu.png',
    '/images/clients/nnobl.png',
    '/images/clients/cbrf.png',
    '/images/clients/sgm.png',
    '/images/clients/tnl.png',
    '/images/clients/mvideo.png',
    '/images/clients/alfabank.png',
    '/images/clients/x5retail.png',
    '/images/clients/pyatyorochka.png',
    '/images/clients/perekryostok.png'
];

type ProjectDescription = Readonly<{
    path: string;
    title: string;
    description: string;
    info: string;
}>;

type ReviewDescription = Readonly<{
    path: string;
    description: string;
    person: string;
    position: string;
}>;

type Description = Readonly<{
    path?: string;
    title?: string|any;
    lines?: string[];
}>;

export const ReviewsInfo: Readonly<ReviewDescription[]> = [
    {
        path: '/images/reviews/client2.png',
        description: 'reviews.client2.description',
        person: 'reviews.client2.person',
        position: 'reviews.client2.position'
    },
    {
        path: '/images/reviews/client1.png',
        description: 'reviews.client1.description',
        person: 'reviews.client1.person',
        position: 'reviews.client1.position'
    }
]

export const ProjectsInfo: Readonly<ProjectDescription[]> = [
    {
        path: '/images/projects/thin-client.png',
        title: 'last-projects.sber-irm.title',
        description: 'last-projects.sber-irm.description',
        info: 'last-projects.sber-irm.info'
    },
    {
        path: '/images/projects/irmview.png',
        title: 'last-projects.thic.title',
        description: 'last-projects.irmview.description',
        info: 'last-projects.sber-irm.info'
    },
    {
        path: '/images/projects/thic.png',
        title: 'last-projects.thic.title',
        description: 'last-projects.thic.description',
        info: 'last-projects.sber-irm.info'
    },
    {
        path: '/images/projects/admin.png',
        title: 'last-projects.admin.title',
        description: 'last-projects.admin.description',
        info: 'last-projects.sber-irm.info'
    },
    {
        path: '/images/projects/alpina.png',
        title: 'last-projects.alpina.title',
        description: 'last-projects.alpina.description',
        info: 'last-projects.alpina.info'
    },

    {
        path: '/images/projects/talents.png',
        title: 'last-projects.talents.title',
        description: 'last-projects.talents.description',
        info: 'last-projects.talents.info'
    },
    {
        path: '/images/projects/sbatm.png',
        title: 'last-projects.sbatm.title',
        description: 'last-projects.sbatm.description',
        info: 'last-projects.sbatm.info'
    },
    {
        path: '/images/projects/mgts.png',
        title: 'last-projects.mgts.title',
        description: 'last-projects.mgts.description',
        info: 'last-projects.mgts.info'
    },
    {
        path: '/images/projects/severstal.png',
        title: 'last-projects.severstal.title',
        description: 'last-projects.severstal.description',
        info: 'last-projects.severstal.info'
    },
    {
        path: '/images/projects/market.png',
        title: 'last-projects.market.title',
        description: 'last-projects.market.description',
        info: 'last-projects.market.info'
    }
];

export const DevelopingInfo: Readonly<Description[]> = [
    {
        path: '/images/features/fullcycle.png',
        title: 'developing-info.fullcycle.title',
        lines: [
            'developing-info.fullcycle.lines.first',
            'developing-info.fullcycle.lines.second',
            'developing-info.fullcycle.lines.third'
        ]
    },
    {
        path: '/images/features/tech.png',
        title: 'developing-info.tech.title',
        lines: [
            'developing-info.tech.lines.first',
            'developing-info.tech.lines.second',
            'developing-info.tech.lines.third'
        ]
    },
    {
        path: '/images/features/effective.png',
        title: 'developing-info.effective.title',
        lines: [
            'developing-info.effective.lines.first',
            'developing-info.effective.lines.second',
            'developing-info.effective.lines.third',
            'developing-info.effective.lines.fourth'

        ]
    }
];

export const ServicesInfo: Readonly<Description[]> = [
    {
        path: '/images/features/development.png',
        title: 'services.development.title',
        lines: [
            'services.development.first',
            'services.development.second',
            'services.development.third'
        ]
    },
    {
        path: '/images/features/migration.png',
        title: 'services.migration.title',
        lines: [
            'services.migration.first',
            'services.migration.second',
            'services.migration.third'
        ]
    },
    {
        path: '/images/features/cloud.png',
        title: 'services.cloud.title',
        lines: [
            'services.cloud.first',
            'services.cloud.second',
            'services.cloud.third'
        ]
    },
    {
        path: '/images/features/point.png',
        title: 'services.point.title',
        lines: [
            'services.point.first',
            'services.point.second',
            'services.point.third',
            'services.point.fourth'
        ]
    }
];

export const ProtectionInfo: Readonly<Description[]> = [
    {
        path: '/images/features/fullcycle.png',
        title: 'protect.fullcycle.title',
        lines: [
            'protect.fullcycle.first',
            'protect.fullcycle.second'
        ]
    },
    {
        path: '/images/features/tech.png',
        title: 'protect.tech.title',
        lines: [
            'protect.tech.first'
        ]
    },
    {
        path: '/images/features/effective.png',
        title: 'protect.effective.title',
        lines: [
            'protect.effective.first'
        ]
    }
];

export const SupportInfo: Readonly<Description[]> = [
    {
        path: '/images/features/24x7.png',
        title: 'support.alltime.title',
        lines: [
            'support.alltime.first',
            'support.alltime.second',
            'support.alltime.third',
            'support.alltime.fourth'
        ]
    },
    {
        path: '/images/features/updates.png',
        title: 'support.updates.title',
        lines: [
            'support.updates.first',
            'support.updates.second',
            'support.updates.third'
        ]
    },
    {
        path: '/images/features/cost.png',
        title: 'support.cost.title',
        lines: [
            'support.cost.first',
            'support.cost.second'
        ]
    },
    {
        path: '/images/features/aggreement.png',
        title: 'support.aggreement.title',
        lines: [
            'support.aggreement.first',
            'support.aggreement.second',
            'support.aggreement.third'
        ]
    }
];

export const OutstaffingInfo: Readonly<Description[]> = [
    {
        title: 'outstaffing.title1'
    },
    {
        title: 'outstaffing.title2'
    },
    {
        title: 'outstaffing.title3'
    }
];

export const BenefitsInfo: Readonly<Description[]> = [
    {
        path: '/images/features/tools.png',
        title: 'benefits.tools.title',
        lines: [
            'benefits.tools.first'
        ]
    },
    {
        path: '/images/features/wide.png',
        title: 'benefits.wide.title',
        lines: [
            'benefits.wide.first'
        ]
    },
    {
        path: '/images/features/areas.png',
        title: 'benefits.areas.title',
        lines: [
            'benefits.areas.first'
        ]
    },
    {
        path: '/images/features/team.png',
        title: 'benefits.team.title',
        lines: [
            'benefits.team.first'
        ]
    },
    {
        path: '/images/features/mspartner.png',
        title: 'benefits.mspartner.title',
        lines: [
            'benefits.mspartner.first'
        ]
    },
    {
        path: '/images/features/yapartner.png',
        title: 'benefits.yapartner.title',
        lines: [
            'benefits.yapartner.first'
        ]
    }
];

import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import $ from 'jquery';
import { appId, pageId, access_token } from '../../../../common';
import { request } from '../../../../api';
import { useTranslation } from 'react-i18next';


export const FacebookPosts = () => {
    const [postIds, setPostIds] = useState([]);
    const {t} = useTranslation()


    const loadLoginSdk = () => {
        window.fbAsyncInit = () => {
            window.FB.init({
                appId,
                xfbml: true,
                version: 'v7.0'
            });
        };

        console.info('Loading FB SDK');

        (function(d, s, id) {
            let js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }

            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/ru_RU/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
            console.info('FB SDK loaded');
        }(document, 'script', 'facebook-jssdk'));
    }

    useEffect(() => {
        loadLoginSdk();
        request('GET', `https://graph.facebook.com/v7.0/${pageId}/feed?access_token=${access_token}&fields=id&format=json&limit=5&method=get`)
        .then((res) => {
            let ids = [];
            res.data.map((item) => ids.push(item.id.split('_')[1]));
            setPostIds(ids);

            // Настраиваем предварительно величины для плавного изменения контейнера, а, затем, возвращаем отображение как было
            // (Bootstrap Carousel просто не умеет в плавное изменение высоты)
            let carousel = document.getElementById('news-carousel');
            carousel.style.opacity = 0;

            let slides = carousel.children[1].children;
            for (let i = 0; i < slides.length; i++) {
                slides[i].style.display = 'block';
            }

            // После рендеринга iframe'ов, выставляем положение высот и возвращаем отображение как было
            // Локально у меня не всегда работает корректно из-за выжранных ресурсов
            setTimeout(() => {
                // Развернем ссылку "еще", чтобы размер был более точный
                for (let seeMore of document.getElementsByClassName('see_more_link_inner')) {
                    seeMore.click();
                }

                for (let i = 0; i < slides.length; i++) {
                    slides[i].setAttribute('frame-height', slides[i].getBoundingClientRect().height + 23);
                    slides[i].style.removeProperty('display');
                }

                carousel.style.opacity = 1;
            }, 8000);
        })
        .catch((err) => console.error(err));
    }, []);

    return postIds.length !== 0 ? (
        <Carousel
            interval={null}
            id="news-carousel"
            onSlide={(slideNumber) => {
                let container = document.getElementById('news-carousel').children[1];
                let nextHeight = container.children[slideNumber].getAttribute('frame-height');
                $(container).animate({ height: nextHeight }, 1000);
            }
        }>
            {postIds.map((item, key) => (
                <Carousel.Item className="text-center" key={key}>
                    <div className="fb-post" data-href={`https://www.facebook.com/${pageId}/posts/${item}`} data-show-text="true" data-width={Math.min(window.screen.width, 800) === 800 ? '750' : 'auto'}></div>
                    <div className="text-center mt-2">
                        <a href={`https://www.facebook.com/${pageId}/posts/${item}`} target="_blank">{t('last-news.show-full-news')}</a>
                    </div>
                </Carousel.Item>
            ))}
        </Carousel>
    ) : <div />;
};

import React from 'react';
import { Row, Col, Media } from 'react-bootstrap';
import { Section } from '../section';
import { ImageItem } from '../image-item';
import { ReviewsInfo } from '../../common';
import { useTranslation } from 'react-i18next';

import './styles.scss'

// Экран "Отзывы клиентов о нашей работе"
export const Reviews: React.FC = () => {

const {t} = useTranslation()
    return(
    <Section
        id="sec-reviews"
        title={t('reviews.title')}
        color="color-2"
        wrapper
    >
        <Row className="features">
            {ReviewsInfo.map((item, key) => (
                <Col md={6} className="review" key={key}>
                    <Media>
                        <ImageItem path={item.path} className="media-img" />
                        <Media.Body>
                            <p>{t(item.description)}</p>
                            <h5>
                                <strong>{t(item.person)}, &nbsp;</strong>
                                <span className="text-muted">{t(item.position)}</span>
                            </h5>
                        </Media.Body>
                    </Media>
                </Col>
            ))}
        </Row>
    </Section>
)};

import React from 'react';

import './styles.scss';

import { useTranslation } from 'react-i18next';


// Компонент для импортирования Yandex-карт. Пакет react-yandex-maps накладывает свои ограничения, поэтому такая реализация
export const YMap = () => {
    const { t } = useTranslation();


    return(
    <>
        <div id="ymap" style={{ position: 'relative', overflow: 'hidden' }}>
            <a href="https://yandex.ru/maps/213/moscow/?utm_medium=mapframe&utm_source=maps" style={{ color: '#eee', fontSize: '12px', position: 'absolute', top: '0px' }}></a>
            <a href="https://yandex.ru/maps/213/moscow/?ll=37.584631%2C55.760449&mode=routes&rtext=55.760818%2C37.581280~55.761354%2C37.587054&rtt=pd&ruri=ymapsbm1%3A%2F%2Fgeo%3Fll%3D37.581%252C55.761%26spn%3D0.001%252C0.001%26text%3D%25D0%25A0%25D0%25BE%25D1%2581%25D1%2581%25D0%25B8%25D1%258F%252C%2520%25D0%259C%25D0%25BE%25D1%2581%25D0%25BA%25D0%25B2%25D0%25B0%252C%2520%25D0%25A2%25D0%25B0%25D0%25B3%25D0%25B0%25D0%25BD%25D1%2581%25D0%25BA%25D0%25BE-%25D0%259A%25D1%2580%25D0%25B0%25D1%2581%25D0%25BD%25D0%25BE%25D0%25BF%25D1%2580%25D0%25B5%25D1%2581%25D0%25BD%25D0%25B5%25D0%25BD%25D1%2581%25D0%25BA%25D0%25B0%25D1%258F%2520%25D0%25BB%25D0%25B8%25D0%25BD%25D0%25B8%25D1%258F%252C%2520%25D0%25BC%25D0%25B5%25D1%2582%25D1%2580%25D0%25BE%2520%25D0%2591%25D0%25B0%25D1%2580%25D1%2580%25D0%25B8%25D0%25BA%25D0%25B0%25D0%25B4%25D0%25BD%25D0%25B0%25D1%258F~ymapsbm1%3A%2F%2Forg%3Foid%3D1351387836&utm_medium=mapframe&utm_source=maps&z=16" style={{ color: '#eee', fontSize: '12px', position: 'absolute', top: '14px' }}>Шаресофт: как доехать на автомобиле, общественным транспортом или пешком – Яндекс.Карты</a><iframe src="https://yandex.ru/map-widget/v1/-/COvovBML" frameBorder="1" allowFullScreen={true} style={{ width: '100%', height: '100%', position: 'relative' }}></iframe>
        </div>
        <div className="ymap-bg"></div>
        <div className="container wrapper">
            <h1><a href="tel:+74956909179">+7 (495) 690-91-79</a></h1>
            <div><a href="mailto:info@sharesoft.ru" title="Есть вопросы? Пишите">info@sharesoft.ru</a></div>
            <div><a style={{ pointerEvents: 'auto' }} href="mailto:hr@sharesoft.ru" title="Для резюме">hr@sharesoft.ru</a></div>
            <div><a href="https://yandex.ru/maps/213/moscow/?ll=37.584631%2C55.760449&mode=routes&rtext=55.760818%2C37.581280~55.761354%2C37.587054&rtt=pd&ruri=ymapsbm1%3A%2F%2Fgeo%3Fll%3D37.581%252C55.761%26spn%3D0.001%252C0.001%26text%3D%25D0%25A0%25D0%25BE%25D1%2581%25D1%2581%25D0%25B8%25D1%258F%252C%2520%25D0%259C%25D0%25BE%25D1%2581%25D0%25BA%25D0%25B2%25D0%25B0%252C%2520%25D0%25A2%25D0%25B0%25D0%25B3%25D0%25B0%25D0%25BD%25D1%2581%25D0%25BA%25D0%25BE-%25D0%259A%25D1%2580%25D0%25B0%25D1%2581%25D0%25BD%25D0%25BE%25D0%25BF%25D1%2580%25D0%25B5%25D1%2581%25D0%25BD%25D0%25B5%25D0%25BD%25D1%2581%25D0%25BA%25D0%25B0%25D1%258F%2520%25D0%25BB%25D0%25B8%25D0%25BD%25D0%25B8%25D1%258F%252C%2520%25D0%25BC%25D0%25B5%25D1%2582%25D1%2580%25D0%25BE%2520%25D0%2591%25D0%25B0%25D1%2580%25D1%2580%25D0%25B8%25D0%25BA%25D0%25B0%25D0%25B4%25D0%25BD%25D0%25B0%25D1%258F~ymapsbm1%3A%2F%2Forg%3Foid%3D1351387836&z=16" target="_blank">{t('adress')}</a></div>
            <div><a href="https://facebook.com/Sharesoft.ru" title="Страница в Facebook" target="_blank"><img src="/images/facebook-icon.png" style={{ height: '44px' }} /></a></div>
        </div>
    </>
    )};

export const request = (method, url, data, withFile) => {
    const headers = new Headers();
    const options = { method };

    if (!withFile) {
        headers.append('Content-Type', 'application/json; charset=utf-8');
        options.headers = headers;
    }

    if (data) {
        if (withFile) {
            options.body = data;
        } else {
            options.body = JSON.stringify(data);
        }
    }

    return fetch(url, options)
        .then(async (res) => {
            if (res.ok) {
                return await res.json();
            }

            return Promise.reject();
        })
        .catch((err) => Promise.reject(err));
};

import React from 'react';
import { Row } from 'react-bootstrap';
import { Section } from '../section';
import { DescriptionItem } from '../description-item';
import { BenefitsInfo } from '../../common';
import { useTranslation } from 'react-i18next';

// Экран "Преимущества работы с нами"
export const Benefits: React.FC = () => {
    const { t } = useTranslation();


    return(
    <Section
        id="sec-benefits"
        title={t('benefits.title')}
        color="color-1"
        wrapper
    >
        <Row>
            {BenefitsInfo.map((item, key) => (
                <DescriptionItem
                    path={item.path}
                    md={6}
                    sm={6}
                    xs={12}
                    title={t(item.title)}
                    imageClass="text-center"
                    key={key}
                >
                    {item.lines &&
                        <>
                            {item.lines!.map((line, key) => (
                                <p key={key} className="mb-3">{t(line)}</p>
                            ))}
                        </>
                    }
                </DescriptionItem>
            ))}
        </Row>
    </Section>
)};

import React, { useState, ChangeEvent } from 'react';
import { Form, FormGroup, Row, Col, Button } from 'react-bootstrap';
import ReCaptcha from '@matt-block/react-recaptcha-v2';
import { request } from '../../../../api';
import { useTranslation } from 'react-i18next';

import './styles.scss';

// Компонент формы для обратной связи
export const FeedbackForm: React.FC = () => {

    const { t } = useTranslation();

    const [validated, setValidated] = useState(false);
    const [sizeExceeded, setSizeExceeded] = useState(false);
    const [captchaPassed, setCaptchaPassed] = useState(false);
    const [captchaResponse, setCaptchaResponse] = useState('');

    // Другого пути, судя по документациям React-Bootstrap и React, нет
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [file, setFile] = useState<File | null>(null);

    // Предварительная валидация полей и отправка сообщения в случае коррректности
    const handleSubmit = (e: any) => {
        e.preventDefault();

        // Для Form.File, видимо, валидацию нормально не завезли
        if (sizeExceeded) {
            document.getElementsByClassName('form-file')[0].classList.remove('is-valid');
            document.getElementsByClassName('form-file')[0].classList.add('is-invalid');
        } else {
            document.getElementsByClassName('form-file')[0].classList.remove('is-invalid');
            document.getElementsByClassName('form-file')[0].classList.add('is-valid');
        }

        // Для reCAPTCHA придется делать тоже вручную
        if (captchaPassed) {
            document.getElementsByClassName('g-recaptcha')[0].classList.remove('is-invalid');
        } else {
            document.getElementsByClassName('g-recaptcha')[0].classList.add('is-invalid');
        }

        let isValid = e.currentTarget.checkValidity() && captchaPassed && ((file && !sizeExceeded) || !file);
        if (!isValid) {
            setValidated(false);
            e.stopPropagation();
        } else {
            let button = document.getElementsByTagName('button')[0];
            button.disabled = true;
            button.classList.add('active');

            // Подготавливаем набор данных с формы и делаем запрос
            let formData = new FormData();
            formData.append('FullName', name);
            formData.append('EMail', email);
            formData.append('Phone', phone);
            formData.append('Text', message);
            formData.append('Captcha', captchaResponse);
            if (file) {
                formData.append('File', file!, file.name);
            }

            const response = request('POST', 'feedback', formData, true);
            response.then((data) => {
                if (data) {
                    let addClassName = data.hasErrors ? 'alert-danger' : 'alert-success';
                    let removeClassName = data.hasErrors ? 'alert-success' : 'alert-danger';
                    let resultDiv = document.getElementsByClassName('result')[0] as HTMLElement;
                    resultDiv.innerHTML = data.message;
                    resultDiv.classList.remove(removeClassName);
                    resultDiv.classList.add(addClassName);
                    resultDiv.style.display = 'block';
                }

                button.disabled = false;
                button.classList.remove('active');
            }).catch(() => {
                button.disabled = false;
                button.classList.remove('active');
                let resultDiv = document.getElementsByClassName('result')[0] as HTMLElement;
                resultDiv.innerHTML = 'Что-то пошло не так :(';
                resultDiv.classList.remove('alert-success');
                resultDiv.classList.add('alert-danger');
                resultDiv.style.display = 'block';
            });
        }

        setValidated(true);
    }

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit} encType="multipart/form-data">
            <FormGroup as={Row}>
                <Form.Label column sm={4}>
                    <strong>{t('feedback.form.name')}</strong>
                </Form.Label>
                <Col sm={8}>
                    <Form.Control
                        placeholder={t('feedback.form.name')}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.currentTarget.value)}
                        required
                    />
                    <Form.Control.Feedback type="invalid">{t('feedback.form.not-empty')}</Form.Control.Feedback>
                </Col>
            </FormGroup>
            <FormGroup as={Row}>
                <Form.Label column sm={4}>
                    <strong>E-mail</strong>
                </Form.Label>
                <Col sm={8}>
                    <Form.Control
                        type="email"
                        placeholder="E-Mail"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.currentTarget.value)}
                        required
                    />
                    <Form.Control.Feedback type="invalid">{t('feedback.form.enter-email-example')}</Form.Control.Feedback>
                </Col>
            </FormGroup>
            <FormGroup as={Row}>
                <Form.Label column sm={4}>
                    <strong>{t('feedback.form.phone-number')}</strong>
                </Form.Label>
                <Col sm={8}>
                    <Form.Control
                        type="tel"
                        pattern="[+]7[0-9]{10}" placeholder={t('feedback.form.phone-number')}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setPhone(e.currentTarget.value)}
                        required
                    />
                    <Form.Control.Feedback type="invalid">{t('feedback.form.enter-phone-example')}</Form.Control.Feedback>
                </Col>
            </FormGroup>
            <FormGroup as={Row}>
                <Form.Label column sm={4}>
                    <strong>{t('feedback.form.message')}</strong>
                </Form.Label>
                <Col sm={8}>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setMessage(e.currentTarget.value)}
                        required
                    />
                    <Form.Control.Feedback type="invalid">{t('feedback.form.not-empty')}</Form.Control.Feedback>
                </Col>
            </FormGroup>
            <FormGroup as={Row}>
                <Form.Label column sm={4}>
                    <strong>{t('feedback.form.attach')} &nbsp;</strong>
                    <span className="text-muted">{t('feedback.form.file-limit')}</span>
                </Form.Label>
                <Col sm={8}>
                    <Form.File
                        
                    
                        className="btn-default"
                        data-browse="Добавить файл"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            if (e.currentTarget.files!.length !== 0) {
                                setSizeExceeded(e.currentTarget.files![0].size > 20971520);
                                setFile(e.currentTarget.files![0])
                            } else {
                                setSizeExceeded(false);
                            }
                        }}
                    />
                    <Form.Control.Feedback type="invalid">Недопустимый размер файла. Принимаются файлы до 20 МБ.</Form.Control.Feedback>
                </Col>
            </FormGroup>
            <FormGroup as={Row}>
                <Col sm={4} />
                <Col sm={8}>
                    <ReCaptcha
                        siteKey="6LcWaasZAAAAAFmRex2SlLPJqxbpb_BITO7TBVaq"
                        theme="light"
                        size="normal"
                        onSuccess={(result: string) => {
                            setCaptchaResponse(result);
                            setCaptchaPassed(true);
                        }}
                    />
                    <Form.Control.Feedback type="invalid" className="recaptcha-error">{t('feedback.form.recaptcha-error')}</Form.Control.Feedback>
                </Col>
            </FormGroup>
            <FormGroup as={Row}>
                <Col sm={12} md={12}>
                    <Button type="submit" className="text-center btn-connect">{t('feedback.form.send-form')}</Button>
                    <span className="ml-2">{t('feedback.form.call-us')}</span>
                    <span className="ml-2"><a href="tel:+74956909179">+7 (495) 690 91 79</a></span>
                </Col>
            </FormGroup>
            <div className="result alert" style={{ display: 'none', marginTop: '10px' }} />
        </Form>
    );
};

import React from 'react';
import { elementScrollIntoView } from 'seamless-scroll-polyfill';

type Props = Readonly<{
    onClick?: () => void
    children: JSX.Element[] | JSX.Element; // То, нажатие на что будет активировать прокрутку
    selector: string;                      // Селектор элемента, к которому нужно прокручивать
    className?: string;
}>;

// Компонент плавной прокрутки к элементу с выбранным селектором
export const ScrollInto: React.FC<Props> = ({ onClick, children, selector, className }) => {
    const scrollIntoView = () => {
        const el = document.querySelector(selector);
        elementScrollIntoView(el!, { behavior: 'smooth' });
        if (onClick) {
            onClick();
        }
    }

    return (
        <div className={className} onClick={scrollIntoView}>{children}</div>
    );
};
